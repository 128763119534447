import 'core-js/stable';
// import 'regenerator-runtime/runtime';

// import { progressJs } from 'progressjs';
import { progressJs } from './progress.js';
import interact from 'interactjs';
import bezier from 'bezier-easing';

var wheelEvent = 'wheel',
	viewportSize = {
		width: window.innerWidth,
		height: window.innerHeight
	},
	Visionneuse,
	Canvas,
	Calendrier,
	maVisionneuse,
	bodyProgress,
	timmy;

/*** Tis loading ***/
bodyProgress = progressJs('body').setOptions({
	theme: 'black',
	overlayMode: true
});
bodyProgress.start();

/*** "Polyfills" ****/
if (typeof (document.createElement('div').onwheel) === 'undefined') {
	if (typeof (document.onmousewheel) !== 'undefined') {
		wheelEvent = 'mousewheel'; // Webkit and IE support at least "mousewheel"
	} else {
		wheelEvent = 'DOMMouseScroll'; // let's assume that remaining browsers are older Firefox)
	}
}

/*********** Classe Visionneuse ***********/
Visionneuse = function (config) {
	'use strict';
	var visi = this;

	visi
		.setConfig(config)
		.then(function () {
			visi.init();
			visi.getVue(config.files[0].fichier);
		});
};
Visionneuse.prototype = {
	calendrier: null,
	canvas: null,
	catalogue: [],
	currPhoto: {
		nom: 'Chargement...',
		fichier: ''
	},
	versusPhotos: {
		vice: {
			nom: 'Chargement...',
			fichier: '',
			index: 0
		},
		versa: {
			nom: 'Chargement...',
			fichier: '',
			index: 0
		}
	},
	popFor: null,
	currIndex: 0,
	currZoom: 0,
	decompte: false,
	maintenance: '',
	deplacement: {
		top: 0,
		left: 0
	},
	origine: {
		top: 0,
		left: 0
	},
	endDraw: true,
	moving: false,
	versa: false,
	/************ Evenements ***********/
	setConfig: function (config) {
		'use strict';
		var visi = this,
			nodeVue = document.querySelector('.vues select'),
			ranger = document.querySelector('#ranger');

		visi.calendrier = new Calendrier();
		visi.canvas = new Canvas(document.getElementById('photos_bloc'));

		return new Promise(function (fulfill, reject) {
			var styles,
				pointBreak = 0;
			config.zoom.max = parseInt(config.zoom.max, 10);
			config.zoom.min = parseInt(config.zoom.min, 10);
			config.zoom.palier = parseInt(config.zoom.palier, 10);
			visi.config = config;

			//Afficher les titres
			document.head.title = config.nom;
			document.querySelector('title').innerHTML = config.nom;
			document.querySelector('.title').innerHTML = config.nom;
			document.querySelector('.subtitle').innerHTML = config.sstitre;
			document.querySelector('#logoBloc img').src = config.img_logo;
			document.querySelector('#logoBloc').href = config.lien_logo;
			ranger.min = config.zoom.min;
			ranger.max = config.zoom.max;
			ranger.step = config.zoom.palier;

			//Supprimer les anciens styles
			styles = document.head.querySelectorAll('style');
			for (pointBreak = 0; pointBreak > styles.length; pointBreak = pointBreak + 1) {
				styles[pointBreak].remove();
			}

			//Si on peut zoomer à + de 100%, on affiche la limite
			if (config.zoom.max > 100) {
				pointBreak = (1 - ((config.zoom.max - 100) / (config.zoom.max - config.zoom.min))) * 100;
				document.head.insertAdjacentHTML('beforeEnd', '<style type="text/css">' + '\n' +
					'#ranger::-webkit-slider-runnable-track { background-image: linear-gradient(to right, white 0%, white ' + pointBreak + '%, #f0ad4e ' + (pointBreak + 1) + '%, #f0ad4e 100%); }' + '\n' +
					'#ranger::-moz-range-track { background-image: linear-gradient(to right, white 0%, white ' + pointBreak + '%, #f0ad4e ' + (pointBreak + 1) + '%, #f0ad4e 100%); }' + '\n' +
					'#ranger::-ms-track { background-image: linear-gradient(to right, white 0%, white ' + pointBreak + '%, #f0ad4e ' + (pointBreak + 1) + '%, #f0ad4e 100%); }' + '\n' +
					'</style>');
			}

			if (config.versus === 'false') {
				document.querySelector('footer').classList.add('noVersus');
			} else {
				document.querySelector('footer').classList.remove('noVersus');
			}

			if (config.contextuel === 'false') {
				visi.canvas.cible.addEventListener('contextmenu', function (e) {
					e.preventDefault();
					return false;
				});
			}

			if (config.style.set !== 'false') {
				document.head.insertAdjacentHTML('beforeEnd', '<style type="text/css">' + '\n' +
					'.bodyPhoto { background-color: ' + config.style.bg_color + '; color: ' + config.style.text_color + '; }' + '\n' +
					'.btn:not(.btnMenu) { background-color: ' + config.style.btn_color + '!important; border-color: ' + config.style.btn_border + '!important; background-image: linear-gradient(' + config.style.btn_dgd + ')!important; color: ' + config.style.btnTxt_color + '!important; }' + '\n' +
					'.btn:not(.btnMenu):hover, .btn:not(.btnMenu).btnActive { background-color: ' + config.style.btn_hover_color + '!important; background-image: linear-gradient(' + config.style.btn_hover_dgd + ')!important; color: ' + config.style.btnTxt_hover_color + '!important; }' + '\n' +
					'.taskbar .btn:not(.disabled), .fleches::after { background-color: ' + config.style.btn_color + '!important; color: ' + config.style.btnTxt_color + '!important; }' + '\n' +
					'.taskbar .btn:hover { background-color: ' + config.style.btn_hover_color + '!important; color: ' + config.style.btnTxt_hover_color + '!important; }' + '\n' +
					'#ranger::-webkit-slider-thumb { background-image: url(img/loupe.svg), linear-gradient(' + config.style.btn_dgd + '); border-color: ' + config.style.btn_border + '!important; }' + '\n' +
					'#ranger::-moz-range-thumb { background-image: url(img/loupe.svg), linear-gradient(' + config.style.btn_dgd + '); border-color: ' + config.style.btn_border + '!important; }' + '\n' +
					'#ranger::-ms-thumb { background-image: url(img/loupe.svg), linear-gradient(' + config.style.btn_dgd + '); border-color: ' + config.style.btn_border + '!important; }' + '\n' +
					'#ranger:hover::-webkit-slider-thumb { background-image: url(img/loupe.svg), linear-gradient(' + config.style.btn_hover_dgd + '); }' + '\n' +
					'#ranger:hover::-moz-range-thumb { background-image: url(img/loupe.svg), linear-gradient(' + config.style.btn_hover_dgd + '); }' + '\n' +
					'#ranger:hover::-ms-thumb { background-image: url(img/loupe.svg), linear-gradient(' + config.style.btn_hover_dgd + '); }' + '\n' +
					'.progressjs-theme-blue .progressjs-inner { background-color: ' + config.style.load_color + '; }' + '\n' +
					'.title { color: ' + config.style.titre_color + '; }' + '\n' +
					'.subtitle, .decompte { color: ' + config.style.sstitre_color + '; }' + '\n' +
					'.maintenance { background-color: ' + config.style.mtnbg_color + '; color: ' + config.style.mtn_color + '; }' + '\n' +
					'.taskbar, #table_calendrier th, #table_calendrier .badge { background-color: ' + config.style.cal_color + '; }' + '\n' +
					'.taskbar-title, #table_calendrier th, #table_calendrier .badge { color: ' + config.style.cal_texte_color + '; }' + '\n' +
					'</style>');
			}

			if (typeof (config.decompte) !== 'undefined' && config.decompte !== 'false') {
				visi.decompte = config.decompte;
				visi.setDecompte();
			}

			if (typeof (config.maintenance) !== 'undefined' && config.maintenance !== '') {
				visi.maintenance = config.maintenance;
				visi.setMaintenance();
			}

			//Vider les vues
			while (nodeVue.firstChild) {
				nodeVue.removeChild(nodeVue.firstChild);
			}
			//Compléter la liste des vues
			config.files.forEach(function (file) {
				var elm = document.createElement('option');
				elm.value = file.fichier;
				elm.innerHTML = file.nom;
				elm.dataSet = {
					download: file.download,
					phd: file.phd,
					tlmn: file.tlmn,
					tlhd: file.tlhd
				};
				nodeVue.appendChild(elm);
			});

			//Dire qu'on a fini
			if (!config.makeCron) {
				fulfill(config);
				return true;
			}
			fetch('cron.php', {
				credentials: 'include'
			})
				.then(function (result) {
					fulfill(result.json());
				})
				.catch(err => {
					reject(err);
				});
		});
	},
	init: function () {
		'use strict';
		var visi = this,
			tips,
			i = 0,
			n = 0;

		interact('#photos_bloc')
			.on('doubletap', visi.openCurrent)
			.draggable({
				inertia: true
			})
			.gesturable({
				inertia: true
			})
			.on('dragmove', visi.dragTo)
			.on('draginertiastart', visi.getMovement)
			.on('dragend', visi.savePosition)
			.on('gesturemove', visi.zoomTo);

		//Zoom zoom zoom
		this.canvas.cible.removeEventListener(wheelEvent, this.zoomTo);
		this.canvas.cible.addEventListener(wheelEvent, this.zoomTo);
		interact('.zoom .right, .zoom .left').on('tap', this.zoomTo);

		//Suivants
		interact('.fleches, .control-pics .right, .control-pics .left').on('tap', this.getPhoto);

		//KIBORDZ
		document.body.removeEventListener('keydown', this.kbShortcut);
		document.body.addEventListener('keydown', this.kbShortcut);

		/*window.removeEventListener('orientationchange', this.orientationChange);
		window.addEventListener('orientationchange', this.orientationChange);*/
		//Resize est "plus précis" vis à vis de ce que je veux obtenir
		console.log(window.innerHeight, window.innerWidth, window.devicePixelRatio, window.matchMedia('(orientation: portrait)'));
		window.removeEventListener('resize', this.orientationChange);
		window.addEventListener('resize', this.orientationChange);

		//Switcheur
		interact('.changer').on('tap', this.switchPhoto);

		//Compareur
		interact('.comparer').on('tap', this.comparePhoto);
		interact('.coupeur').draggable({
			inertia: false,
			//onstart: this.comparePhoto,
			onmove: this.comparePhoto,
			//onend: this.comparePhoto
		});

		//Zoom
		document.getElementById('ranger').addEventListener('input', this.zoomTo);
		document.getElementById('ranger').addEventListener('change', this.zoomTo);

		//Changement de vue
		document.querySelector('.vues select').addEventListener('change', this.getVue);

		//Clic sur nom = calendrier
		interact('.recto, .verso, .courante').on('tap', this.popThing);

		//Utilesh
		interact('.more').on('tap', this.popThing);
		//Refresh
		interact('.refresh').on('tap', this.refresh);
		//MailFresh
		interact('.mail').on('tap', this.mailTo);
		//Helpesh
		interact('.help').on('tap', this.showHelp);
		//Descesh
		interact('.title').on('tap', this.showDesc);
		//Hidesh
		interact('body').on('tap', this.hideThing);

		//Tooltips
		tips = document.querySelectorAll('[aria-labelledby]');
		for (i = 0, n = tips.length; i < n; i = i + 1) {
			tips[i].addEventListener('mouseover', function (e) {
				clearTimeout(timmy);
				timmy = setTimeout(function () {
					//console.log(e.target.getAttribute('aria-labelledby'));
					if (e.target.getAttribute('aria-labelledby') === null) {
						visi.tooltip(e.target.parentNode, false);
					} else {
						visi.tooltip(e.target, false);
					}
				}, 500);
			});
			tips[i].addEventListener('mouseout', function (e) {
				clearTimeout(timmy);
				visi.tooltip(e.target, true);
			});
		}

		//Q'n'D hack pour redim le canvas quand il a vraiment sa taille
		window.addEventListener('load', function () {
			visi.orientationChange();
		});
	},
	orientationChange: function () {
		'use strict';
		var visi = this;
		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}
		//Re-def taille viewport
		viewportSize.width = window.innerWidth;
		viewportSize.height = window.innerHeight;
		//Re-init canvas
		visi.canvas.cible.width = visi.canvas.cible.clientWidth;
		visi.canvas.cible.height = visi.canvas.cible.clientHeight;
		visi.canvas.setDimension(visi.config.ajustement);
		visi.affichePhoto();
	},
	popThing: function (elmt) {
		'use strict';
		var visi = this,
			cible = null,
			pos = {
				x: 0,
				y: 0
			},
			transitionEnd;
		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}

		if (elmt !== false && typeof (elmt.target) !== 'undefined') {
			if (typeof (elmt.target.classList) !== 'undefined' && elmt.target.classList.contains('more')) {
				cible = document.querySelector('.plus');
			} else if (elmt.target.id === 'cell') {
				//cible = document.querySelector('.grille');
				cible = document.getElementById('container_calendrier');
				//elmt.target.offsetParent.offsetTop = (parseFloat(cible.getAttribute('data-x')) || 0);
				//elmt.target.offsetParent.offsetLeft = (parseFloat(cible.getAttribute('data-y')) || 0);
				cible.classList.add('pics');
				cible.classList.remove('cal');
			} else {
				//cible = visi.calendrier.corps;
				cible = document.getElementById('container_calendrier');
				cible.classList.remove('pics');
				cible.classList.add('cal');

				//Maj la date affichée
				visi.calendrier.setFullDate(elmt.target.dataset.date);
				visi.calendrier.renderMonth();
				visi.calendrier.countPhoto(maVisionneuse);
			}
		} else {
			document.querySelector('.plus').setAttribute('hidden', 'hidden');
			document.getElementById('container_calendrier').setAttribute('hidden', 'hidden');
			return true;
		}

		if (elmt !== false && visi.popFor != elmt.target) {
			document.querySelector('.plus').setAttribute('hidden', 'hidden');
			document.getElementById('container_calendrier').setAttribute('hidden', 'hidden');
			if (elmt === false) {
				visi.popFor = null;
			}
		}
		if (cible.hasAttribute('hidden')) {
			cible.removeAttribute('hidden');

			//Définir la position
			//console.log(elmt.target.offsetParent.offsetTop, elmt.target.offsetTop, cible.offsetHeight);
			//cible.style.top = ((elmt.target.offsetParent.offsetTop + elmt.target.offsetTop) - cible.offsetHeight) + 'px';
			if (parseFloat(cible.getAttribute('data-x'), 10) > 0) {
				pos.y = parseFloat(cible.getAttribute('data-y'), 10);
				pos.x = parseFloat(cible.getAttribute('data-x'), 10);
			} else {
				pos.y = (elmt.target.offsetParent.offsetTop - cible.offsetHeight);
				pos.x = ((elmt.target.offsetLeft + (elmt.target.offsetWidth / 2)) - (cible.offsetWidth / 2));
			}
			if (viewportSize.width < pos.x + cible.offsetWidth) {
				//Déborde à gauche
				pos.x = (viewportSize.width - (cible.offsetWidth + 16));
			} else if (pos.x <= 0) {
				//Déborde à droite
				pos.x = 16;
			}

			cible.style.transform = 'translate(' + pos.x + 'px, ' + pos.y + 'px)';
			cible.setAttribute('data-x', pos.x);
			cible.setAttribute('data-y', pos.y);
			cible.style.opacity = 1;

			visi.popFor = elmt.target;
		} else {
			cible.style.opacity = 0;
			transitionEnd = function () {
				cible.setAttribute('hidden', 'hidden');
				cible.removeEventListener('transitionend', transitionEnd, false);
			};
			cible.addEventListener('transitionend', transitionEnd, false);
			visi.popFor = null;
		}
	},
	hideThing: function (e) {
		'use strict';
		var visi,
			closest,
			parent,
			poppy;

		/* http://stackoverflow.com/questions/18663941/finding-closest-element-without-jquery */
		closest = function (el, selector) {
			var matchesFn;

			// find vendor prefix
			['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
				if (typeof (document.body[fn]) === 'function') {
					matchesFn = fn;
					return true;
				}
				return false;
			});

			// traverse parents
			while (el !== null) {
				parent = el.parentElement;
				if (parent !== null && parent[matchesFn](selector)) {
					return parent;
				}
				el = parent;
			}

			return null;
		};
		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}

		poppy = closest(e.target, '.popUp');
		//console.log(poppy, visi.popFor);
		if (poppy === null) {
			//On est pas dans un des poppé, mais est-on un des boutons ?
			poppy = closest(e.target, 'footer');
			if (poppy === null) {
				//Non plus ? Alors si un pop ouvert, fermer
				if (visi.popFor !== null) {
					visi.popThing(false);
				}
			}
		}

		visi.tooltip(document.getElementById('corpsVisionneuse'), true);

		//e.target.dispatchEvent(new Event(e.srcEvent.type));
		return true;
	},
	tooltip: function (elmt, hide) {
		var tip = document.querySelector('.tooltip'),
			bound = elmt.getBoundingClientRect(),
			pos = {
				x: 0,
				y: 0
			};

		if (typeof (hide) === 'undefined') {
			hide = false;
		}

		if (hide) {
			tip.classList.remove('shown');
		} else {
			tip.children[0].innerHTML = elmt.getAttribute('aria-labelledby');
			tip.style.marginLeft = ((tip.getBoundingClientRect().width / 2) * -1) + 'px';

			pos.x = bound.top - 20 - 20; //-20 pour la margin, -20 pour le triangle
			pos.y = bound.left + (elmt.offsetWidth / 2);
			if (elmt.classList.contains('fleches')) {
				pos.x = (viewportSize.height / 3);
			}
			//Retournement car débordement haut ?
			if (pos.x < tip.offsetHeight) {
				tip.classList.add('upsideDown');
				pos.x = tip.offsetHeight;
			} else {
				tip.classList.remove('upsideDown');
			}
			//Débordements latéraux
			if (pos.y < tip.offsetWidth) {
				pos.y = (tip.offsetWidth / 2) + 6;
			}
			//console.log(pos.y, (viewportSize.width - (tip.offsetWidth / 2)), viewportSize.width, tip.offsetWidth);
			if (pos.y > (viewportSize.width - (tip.offsetWidth / 2))) {
				pos.y = viewportSize.width - (tip.offsetWidth / 2) + 6;
			}

			//console.log(pos);
			tip.style.top = pos.x + 'px';
			tip.style.left = pos.y + 'px';
			tip.classList.add('shown');
		}

		return tip;
	},
	activity: function (start) {
		'use strict';
		var spinner = document.getElementById('header_spinner');
		if (typeof (start) === 'undefined') {
			start = true;
		}

		if (start) {
			spinner.removeAttribute('hidden');
		} else {
			spinner.setAttribute('hidden', 'hidden');
		}

		return start;
	},
	/******** Affichage Photo **********/
	openCurrent: function (e) {
		'use strict';
		var visi = this;
		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}
		if (e.type === 'doubletap') {
			if (visi.currZoom > visi.config.zoom.min) {
				visi.zoomTo(visi.config.zoom.min, {
					x: e.clientX,
					y: e.clientY,
					deltaX: 0,
					deltaY: 0
				});
			} else {
				visi.zoomTo(visi.config.zoom.max, {
					x: e.clientX,
					y: e.clientY,
					deltaX: 0,
					deltaY: 0
				});
			}
		} else {
			//window.open(window.location.href + visi.currPhoto);
			console.log(visi.currPhoto);
		}
	},
	affichePhoto: function (zoom, deplacement) {
		'use strict';
		var visi = this;

		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}

		if (typeof (zoom) === 'undefined') {
			zoom = visi.currZoom;
		}
		if (typeof (deplacement) === 'undefined') {
			deplacement = visi.deplacement;
		}

		//Vérification du canvas et des portes opposées
		if ((visi.canvas.cible.width !== visi.canvas.cible.clientWidth) || (visi.canvas.cible.height !== visi.canvas.cible.clientHeight)) {
			visi.canvas.cible.width = visi.canvas.cible.clientWidth;
			visi.canvas.cible.height = visi.canvas.cible.clientHeight;
		}
		visi.activity();

		//Set du nom en bas de page
		document.querySelector('.courante').innerHTML = visi.calendrier.formatDate(visi.currPhoto.nom.substr(0, 10)) + '<br />' + visi.currPhoto.nom.substr(11, 5); //16 c'est à peu près 2015/01/01 08:50
		document.querySelector('.courante').setAttribute('data-date', visi.currPhoto.nom);
		//Set du courant
		//document.querySelector('.recto').innerHTML = visi.currPhoto.nom;
		//Set de l'opposé
		//document.querySelector('.verso').innerHTML = visi.versusPhoto.nom;
		//Set du téléchargement en bas de page
		document.querySelectorAll('.save')[0].href = visi.catalogue.params.dossier + '/' + visi.currPhoto.fichier;
		document.querySelectorAll('.save')[1].href = visi.catalogue.params.dossier + '/' + visi.currPhoto.fichier;
		document.querySelectorAll('.save')[0].download = visi.currPhoto.nom + '.jpg';
		document.querySelectorAll('.save')[1].download = visi.currPhoto.nom + '.jpg';
		//Affichage/masquage des flèches
		if (visi.currIndex === (visi.catalogue.files.length - 1)) {
			document.querySelector('.right').style.visibility = 'hidden';
			document.querySelector('.left').style.visibility = 'visible';
		} else {
			if (document.querySelector('.right').style.visibility === 'hidden') {
				document.querySelector('.right').style.visibility = 'visible';
			}

			if (visi.currIndex === 0) {
				document.querySelector('.left').style.visibility = 'hidden';
			} else if (document.querySelector('.left').style.visibility === 'hidden') {
				document.querySelector('.left').style.visibility = 'visible';
			}
		}

		return visi.displayCanvas(zoom, deplacement);
	},
	displayCanvas: function (zoom, deplacement) {
		'use strict';
		var imageURI = '',
			visi = this,
			request,
			progress,
			canvasLoaded;

		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}
		imageURI = visi.catalogue.params.dossier + '/' + visi.currPhoto.fichier;
		/*if (visi.catalogue.params.mini == 'true') {
			// Valider les params de mini
			if (visi.validateParamsMini(visi.catalogue.params.params_miniatures)) {
				// Dire que les images sont dans /mini
				imageURI = visi.catalogue.params.dossier + '/mini/' + visi.currPhoto.fichier;
			}
		}*/

		//Définition du zoom
		if (typeof (zoom) === 'undefined') {
			//Zoom = 0 : affichage plein cadre par défaut
			zoom = visi.currZoom;
		}
		//Définition du décalage
		if (typeof (deplacement) === 'undefined') {
			deplacement = visi.deplacement;
		}
		//Définition centrage si zoom 0
		if (zoom === 0) {
			deplacement = {
				top: 0,
				left: 0
			};
			visi.deplacement = deplacement;
		}
		//Déclaration du début de traçage
		visi.endDraw = false;

		//console.log('SRC ?', visi.canvas.image.src, imageURI, visi.canvas.image.src.indexOf(imageURI));
		//Chargement de l'image
		if (visi.canvas.image.src.indexOf(imageURI) === -1) {
			//Ce n'est pas l'image active dans le canvas !
			if (sessionStorage.getItem('visi-' + imageURI) !== null) {
				visi.canvas.image.src = imageURI;
				canvasLoaded = new Promise(function (fulfill, reject) {
					visi.canvas.image.onload = function (ev) {
						fulfill(ev);
					};
					visi.canvas.image.onerror = function (ev) {
						reject(ev);
					};
				});
			} else {
				request = new XMLHttpRequest();
				canvasLoaded = new Promise(function (fulfill, reject) {
					progress = progressJs('.infos').setOptions({
						theme: 'blue',
						overlayMode: true,
						percentMode: true
					});
					request.onloadstart = function () {
						progress.start();
					};
					request.onprogress = function (e) {
						if (e.lengthComputable) {
							progress.set(e.loaded / e.total * 100);
						}
					};
					request.onloadend = function () {
						progress.end();

						sessionStorage.setItem('visi-' + imageURI, 'true');
						visi.canvas.image.src = imageURI;

						visi.canvas.image.onload = function (ev) {
							fulfill(ev);
						};
						visi.canvas.image.onerror = function (ev) {
							reject(ev);
						};
					};
					request.open('GET', imageURI, true);
					request.send(null);
				});
			}
			canvasLoaded
				.then(function () {
					/*if (redim === false) {
						//It's bigger on the inside !!!!
						if (image.src.search('/mini/') !== -1) {
							//On le refait avec la source orignale
							maVisionneuse.currPhoto = image.src.replace('/mini/', '/');
							maVisionneuse.affichePhoto();
							return true;
						} else {
							//On a pas plus grand, on affiche...
							//Centrage, tout ça
							centerH = Math.max(0, Math.floor((canvH / 2) - (imgH / 2)));
							centerW = Math.max(0, Math.floor((canvW / 2) - (imgW / 2)));
							this.ctx_canvas.drawImage(image, centerW, centerH, imgW, imgH);
							return true;
						}
					}*/
					visi.canvas
						.setDimension(visi.config.ajustement)
						.move(zoom, deplacement)
						.then(function (canvas) {
							return canvas.draw(false);
						})
						.then(function () {
							visi.endDraw = true;
							visi.activity(false);
						})
						.catch(function () {
							visi.endDraw = true;
							visi.activity(false);
						});
				})
				.catch(function () {
					visi.endDraw = true;
					visi.activity(false);
					if (visi.canvas.image.src.search('/mini/') !== 1) {
						//On le refait avec la source orignale
						maVisionneuse.currPhoto = visi.canvas.image.src.replace('/mini/', '/');
						maVisionneuse.displayCanvas();
						return true;
					} else {
						alert('La photo n\'a pas pu être chargée');
						return false;
					}
				});
		} else {
			//C'est déjà elle, on la tripote
			visi.canvas
				.setDimension(visi.config.ajustement)
				.move(zoom, deplacement)
				.then(function (canvas) {
					return canvas.draw(visi.moving);
				})
				.then(function () {
					visi.endDraw = true;
					visi.activity(false);
				})
				.catch(function () {
					visi.endDraw = true;
					visi.activity(false);
				});
		}
		return true;
	},
	/******* Deplacement Photo ********/
	getMovement: function (e) {
		'use strict';
		var visi = this;
		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}

		//Pas de swipe quand on zoom
		if (visi.canvas.cible.classList.contains('zoomed')) {
			//console.log('Move !', e);
			return visi.dragTo(e);
		} else {
			if (e.swipe) {
				//console.log('Swipe !', e.swipe);
				if (e.swipe.left) {
					return visi.getPhoto('next');
				} else if (e.swipe.right) {
					return visi.getPhoto('prev');
				}
			}
		}

		return visi;
	},
	checkPosition: function () {
		'use strict';
		var visi = this;

		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}
		if (visi.canvas.debordements.calcVertical > 0) {
			//Si on peut déborder en haut et bas
			if (Math.abs(visi.deplacement.top) > (visi.canvas.debordements.calcVertical / 2)) {
				if (visi.deplacement.top < 0) {
					visi.deplacement.top = (visi.canvas.debordements.calcVertical / 2) * -1;
				} else {
					visi.deplacement.top = (visi.canvas.debordements.calcVertical / 2);
				}
			}
		} else {
			visi.deplacement.top = 0;
		}
		if (visi.canvas.debordements.calcHorizontal > 0) {
			//Si on peut déborder à gauche et droite
			if (Math.abs(visi.deplacement.left) > (visi.canvas.debordements.calcHorizontal / 2)) {
				if (visi.deplacement.left < 0) {
					visi.deplacement.left = (visi.canvas.debordements.calcHorizontal / 2) * -1;
				} else {
					visi.deplacement.left = (visi.canvas.debordements.calcHorizontal / 2);
				}
			}
		} else {
			visi.deplacement.left = 0;
		}

		return visi;
	},
	savePosition: function () {
		'use strict';
		var visi = this,
			toptop = 0,
			leftleft = 0;
		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}
		toptop = parseFloat(visi.deplacement.top, 10);
		leftleft = parseFloat(visi.deplacement.left, 10);

		//console.log('stock');
		//console.log(visi);
		//console.log(visi.deplacement);
		//console.log(visi.origine);
		visi.origine = {
			top: toptop,
			left: leftleft
		};
		//console.log(visi.origine);

		return visi;
	},
	dragTo: function (e) {
		'use strict';
		var visi = this,
			mouvement = {
				top: 0,
				left: 0
			};
		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}
		mouvement = visi.origine;
		visi.moving = true;

		//masquage du vs au cas où
		document.querySelector('.coupeur').classList.add('hidden');
		document.querySelector('.comparer').classList.remove('flip');

		//console.log('visi', visi);
		//console.log('position', mouvement);
		//console.log('deplacement', visi.deplacement);
		if (visi.currZoom > 0) {
			//On a zoomé un peu, on doit pouvoir déplacer
			visi.deplacement.top = mouvement.top + e.dy;
			visi.deplacement.left = mouvement.left + e.dx;

			//console.log(visi.deplacement, e.dy, e.dx);
			//Vérification des débordements
			visi.checkPosition();
			//Enregistrement du déplacement
			visi.savePosition();
			//console.log(visi.deplacement);

			if (visi.endDraw) {
				return visi.displayCanvas();
			} else {
				return false;
			}
		} else {
			return false;
		}
	},
	zoomTo: function (e, center) {
		'use strict';
		var visi = this,
			baseZoom = 0,
			currZoom = 0,
			zoomMax = 100,
			zoomMin = 0,
			zoomable = false;
		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}
		zoomMax = visi.config.zoom.max;
		zoomMin = visi.config.zoom.min;

		//masquage du vs au cas où
		document.querySelector('.coupeur').classList.add('hidden');
		document.querySelector('.comparer').classList.remove('flip');

		if (typeof (center) === 'undefined') {
			center = {
				//La position 0,0 c'est le milieu du canvas
				x: (visi.canvas.dimensions.canvas.width / 2),
				y: (visi.canvas.dimensions.canvas.height / 2),
				deltaX: 0,
				deltaY: 0
			};
		}

		visi.activity();
		baseZoom = visi.currZoom;
		currZoom = visi.currZoom;
		//console.log('Zoom', e);
		//console.log('Dataset ?', typeof (e.target.dataset.sens));
		//console.log('Scale ?', typeof (e.scale));
		//console.log('Delta ?', typeof (e.deltaY));
		if (typeof (e.target) !== 'undefined' && typeof (e.target.dataset.sens) !== 'undefined') {
			if (e.target.dataset.sens === 'plus') {
				//console.log('pilus', currZoom);
				currZoom = currZoom + visi.config.zoom.palier;
				if (currZoom > zoomMax) {
					currZoom = zoomMax;
				}
			} else {
				//console.log('minus', currZoom);
				currZoom = currZoom - visi.config.zoom.palier;
				if (currZoom < zoomMin) {
					currZoom = zoomMin;
				}
			}
			/* Non mais on anime pas avec le clavier */
			if (currZoom > zoomMin) {
				zoomable = true;
			}
			/**/
		} else if (typeof (e.scale) !== 'undefined') {
			//console.log('Gesture', e.scale, e.ds, e.speed);
			center.x = e.clientX;
			center.y = e.clientY;
			if (e.ds > 0) { //Pinch out
				if (currZoom <= zoomMin) {
					//Sinon, 0 * qqch ça fait rien, ou ça part en négatif
					currZoom = zoomMin + 1;
				}
				currZoom = currZoom * (1 + e.ds);
				if (currZoom > zoomMax) {
					currZoom = zoomMax;
				}
			} else if (e.ds < 0) { //Pinch in
				currZoom = currZoom * (1 + e.ds);
				if (currZoom < zoomMin) {
					currZoom = zoomMin;
				}
			}
			/* Non mais avec les doigts ça s'anime pas
			if (currZoom > zoomMin) {
				zoomable = true;
			}
			*/
		} else if (typeof (e.deltaY) !== 'undefined' || typeof (e.wheelDelta) !== 'undefined') {
			//console.log('Zoom Center', e.x, e.y);
			center.x = e.clientX;
			center.y = e.clientY;
			//console.log('Wheel', e);
			//document.getElementById('punkt').style.display = 'none';
			if (e.deltaY < 0 || e.wheelDelta > 0) {
				//+5% de zoom, arbitraire
				currZoom = currZoom + 5;
				if (currZoom > zoomMax) {
					currZoom = zoomMax;
				}
			} else {
				//-5% de zoom, arbitraire
				currZoom = currZoom - 5;
				if (currZoom < zoomMin) {
					currZoom = zoomMin;
				}
			}
			/* Non mais avec la souris ça s'anime pas non plus
			if (currZoom > zoomMin) {
				zoomable = true;
			}
			*/
		} else if (typeof (e.target) !== 'undefined' && typeof (e.target.value) !== 'undefined') {
			currZoom = parseFloat(e.target.value, 10);
			//console.log(e.type, currZoom, baseZoom);
			if (e.type !== 'input') {
				if (currZoom > 0) {
					zoomable = true;
				} else {
					if (visi.currZoom > 0 && currZoom === zoomMin) {
						//Si on veut dézoomer à 0 d'un coup, pour pouvoir animer
						zoomable = true;
					}
				}
				e.target.blur();
			} else {
				if (currZoom > baseZoom) {
					if ((currZoom - baseZoom) > (visi.config.zoom.palier * 2)) {
						//On vient de faire un grand saut ! Attendons le change
						currZoom = baseZoom;
						return true;
					}
				} else {
					if ((baseZoom - currZoom) > (visi.config.zoom.palier * 2)) {
						//On vient de faire un grand saut ! Attendons le change
						baseZoom = currZoom;
						return true;
					}
				}
			}
		} else {
			currZoom = parseFloat(e, 10);

			if (currZoom > 0) {
				zoomable = true;
			} else {
				if (visi.currZoom > 0 && currZoom === zoomMin) {
					//Si on veut dézoomer à 0 d'un coup, pour pouvoir animer
					zoomable = true;
				}
			}
		}

		//Gestion du centrage de zoom
		center.deltaX = ((visi.canvas.dimensions.canvas.width / 2) - center.x) / visi.canvas.dimensions.canvas.width;
		center.deltaY = ((visi.canvas.dimensions.canvas.height / 2) - center.y) / visi.canvas.dimensions.canvas.height;
		//console.log('Zoom Center', center);
		//document.getElementById('punkt').style.left = center.x + 'px';
		//document.getElementById('punkt').style.top = center.y + 'px';
		if (center.deltaX !== 0) {
			visi.deplacement.left = visi.canvas.debordements.calcHorizontal * center.deltaX;
		}
		if (center.deltaY !== 0) {
			visi.deplacement.top = visi.canvas.debordements.calcVertical * center.deltaY;
		}

		visi.currZoom = currZoom;
		if (currZoom > 0) {
			visi.canvas.cible.classList.add('zoomed');
		} else {
			visi.canvas.cible.classList.remove('zoomed');
			visi.deplacement = {
				top: 0,
				left: 0
			};
		}
		visi.savePosition();
		document.getElementById('ranger').value = currZoom.toString();

		//Vérification des débordements
		visi.checkPosition();
		if (Object.prototype.hasOwnProperty.call(window, 'requestAnimationFrame') && zoomable) {
			//console.log({ base: baseZoom, zoom: currZoom });
			return visi.animate({
				zoom: baseZoom,
				deltaX: 0,
				deltaY: 0
			}, {
				zoom: currZoom,
				deltaX: center.deltaX,
				deltaY: center.deltaY
			});
		} else {
			return visi.displayCanvas();
		}
	},
	animate: function (from, to, duree) {
		'use strict';
		var visi = this,
			easeOut = bezier(0.00, 0.0, 0.58, 1.0),
			delta = {
				zoom: to.zoom - from.zoom,
				x: to.deltaX - from.deltaX,
				y: to.deltaY - from.deltaY
			};
		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}
		if (typeof (duree) === 'undefined') {
			duree = 350;
		}

		//console.log(from, to, delta);
		return new Promise(function (fulfill) {
			var start = Date.now();
			(function loop() {
				var p = (Date.now() - start) / duree,
					easeTo = easeOut(p);
				if (p > 1) {
					visi.currZoom = to.zoom;
					visi.moving = false;
					visi.savePosition();
					visi.displayCanvas();
					fulfill(to);

					return true;
				} else {
					requestAnimationFrame(loop);
					visi.currZoom = from.zoom + (delta.zoom * easeTo);

					if (to.deltaX !== 0) {
						//visi.deplacement.left = visi.canvas.debordements.calcHorizontal * (delta.x * easeTo);
						visi.deplacement.left = visi.canvas.debordements.calcHorizontal * delta.x;
					}
					if (to.deltaY !== 0) {
						//visi.deplacement.top = visi.canvas.debordements.calcVertical * (delta.y * easeTo);
						visi.deplacement.top = visi.canvas.debordements.calcVertical * delta.y;
					}
					visi.checkPosition();
					//console.log(delta, p, visi.currZoom);
					visi.displayCanvas();
				}
			}());
		});
	},
	/******* Gestion "catalogue" ***********/
	setCatalogue: function (cat, idx, zoom) {
		'use strict';
		var visi = this;

		this.activity();

		visi.catalogue = cat;
		visi.currIndex = cat.files.length - 1; //Pour afficher la dernière (plus récente)
		visi.currZoom = 0;

		if (typeof (idx) !== 'undefined') {
			visi.currIndex = idx;
		}
		if (cat.files.length > 0) {
			visi.currPhoto = cat.files[visi.currIndex];
			//Versa la dernière
			visi.versusPhotos.versa = {
				nom: visi.currPhoto.nom,
				fichier: visi.currPhoto.fichier,
				index: visi.currIndex
			};
			//Vice la 1ere
			/*visi.versusPhotos.vice = {
				nom: cat.files[0].nom,
				fichier: cat.files[0].fichier,
				index: 0
			};*/
			//En fait la 1ere en milieu de journée.
			visi.versusPhotos.vice = cat.files.filter(function (elm, i) {
				elm.index = i;
				return (elm.nom.search(cat.files[0].nom.substr(0, 10)) !== -1);
			});
			visi.versusPhotos.vice = visi.versusPhotos.vice.filter(function (elm) {
				var datum = new Date(elm.nom);
				return (datum.getHours() === 12);
			});
			if (visi.versusPhotos.vice.length === 0) {
				//Yapamidi ? On reprend du début !
				visi.versusPhotos.vice = cat.files.filter(function (elm, i) {
					elm.index = i;
					return (elm.nom.search(cat.files[0].nom.substr(0, 10)) !== -1);
				});
				//Yakachéché plus large
				visi.versusPhotos.vice = visi.versusPhotos.vice.filter(function (elm) {
					var datum = new Date(elm.nom);
					return (datum.getHours() === 11 || datum.getHours() === 13);
				});
			}
			if (visi.versusPhotos.vice.length === 0) {
				//Yapagranchoz ? On reprend du début !
				visi.versusPhotos.vice = cat.files.filter(function (elm, i) {
					elm.index = i;
					return (elm.nom.search(cat.files[0].nom.substr(0, 10)) !== -1);
				});
			}
			visi.versusPhotos.vice = visi.versusPhotos.vice[Math.ceil(visi.versusPhotos.vice.length / 2) - 1];
			visi.versusPhotos.vice = {
				nom: visi.versusPhotos.vice.nom,
				fichier: visi.versusPhotos.vice.fichier,
				index: visi.versusPhotos.vice.index
			};

			//Affichage première et dernière pour versus
			document.querySelector('.recto').innerHTML = visi.calendrier.formatDate(visi.versusPhotos.vice.nom.substr(0, 10)); //Pas besoin de l'heure ici
			document.querySelector('.recto').setAttribute('data-date', visi.versusPhotos.vice.nom);
			document.querySelector('.verso').innerHTML = visi.calendrier.formatDate(visi.versusPhotos.versa.nom.substr(0, 10)); //Pas besoin de l'heure ici
			document.querySelector('.verso').setAttribute('data-date', visi.versusPhotos.versa.nom);
		}

		if (typeof (zoom) !== 'undefined') {
			visi.currZoom = zoom;
		}
		document.getElementById('ranger').value = visi.currZoom;

		this.activity(false);

		return visi;
	},
	validateParamsMini: function (params) {
		var miniValide = false;

		if (typeof (params) !== 'undefined') {
			if (params.ratio_h > 0 && params.ratio_w > 0) {
				miniValide = true;
			} else if (params.resize > 0) {
				miniValide = true;
			} else if (params.rotation > 0) {
				miniValide = true;
			} else if (params.filigrane_text != '') {
				miniValide = true;
			}
		}

		return miniValide;
	},
	getVue: function (fichier) {
		'use strict';
		var visi = this;

		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}
		if (typeof (fichier.target) !== 'undefined') {
			fichier.target.blur();
			fichier = fichier.target.value;
		}

		bodyProgress.start();
		bodyProgress.set(33);
		return fetch(fichier + '?rand=' + new Date().getTime(), {
			credentials: 'include'
		})
			.then(function (reponse) {
				bodyProgress.set(50);
				return reponse.json();
			})
			.then(function (json) {
				var paramsFull = {};
				//console.info('Vue ' + fichier + ' chargée', json);
				bodyProgress.end();

				//Maj le catalogue interne
				paramsFull = visi.config.files.filter(function (elem) { return (fichier === elem.fichier); })[0];
				paramsFull.catalogue = json.config.params.catalogue;
				paramsFull.dossier = json.config.params.dossier;
				paramsFull.mini = json.config.params.mini;
				paramsFull.download = json.config.params.download;
				json.config.params = paramsFull;
				visi.setCatalogue(json.config);
				//Maj le calendrier
				if (visi.catalogue.files.length > 0) {
					visi.calendrier.setFullDate(visi.catalogue.files[visi.catalogue.files.length - 1].nom);
				} else {
					visi.calendrier.setFullDate(new Date());
				}
				visi.calendrier.renderMonth();
				//Et badgage
				visi.calendrier.countPhoto(visi);
				//Et limitage
				if (visi.catalogue.files.length > 0) {
					visi.calendrier.borneMin = new Date(visi.catalogue.files[0].nom);
					visi.calendrier.borneMax = new Date(visi.catalogue.files[visi.catalogue.files.length - 1].nom);
				} else {
					visi.calendrier.borneMin = new Date();
					visi.calendrier.borneMax = new Date();
				}
				//Et surLimitage
				if (paramsFull.download === 'false') {
					document.querySelector('footer').classList.add('noDL');
					document.querySelector('.plus').classList.add('noDL');
				} else {
					document.querySelector('footer').classList.remove('noDL');
					document.querySelector('.plus').classList.remove('noDL');
				}

				//Définir le zoom courant
				visi.currZoom = visi.config.zoom.min;
				if (visi.currZoom > 0) {
					//Ajouter le zoomed pour pas swiper
					visi.canvas.cible.classList.add('zoomed');
				}
				//Afficher la photo
				visi.affichePhoto();

				//Retourner qqch pour les then
				return visi;
			})
			.catch(function (err) {
				console.error(err); //Erreur qqpart..juste json ?
			});
	},
	getDay: function (date, midi, verso) {
		'use strict';
		var dates = [],
			idx = [],
			idxUtile = 0,
			visi = this,
			nodeVue = null;

		if (typeof (midi) === 'undefined') {
			midi = false;
		}
		if (visi.catalogue.params.mini === 'false') {
			//Si on gère pas les minis, on aura le droit qu'à la photo à midi
			midi = true;
		}
		if (typeof (verso) === 'undefined') {
			verso = false;
		}

		//Filtrer au jour en cours
		dates = this.catalogue.files.filter(function (elm, i) {
			elm.index = i;
			return (elm.nom.search(date) !== -1);
		});
		if (dates.length > 0) {
			if (midi) {
				//Si le calendrier est affiché pour le recto ou verso, chercher si midi
				idx = dates.filter(function (elm) {
					var datum = new Date(elm.nom);
					return (datum.getHours() === 12);
				});

				if (idx.length > 0) {
					idxUtile = idx[0].index;
				} else {
					//On essaie à une heure près
					idx = dates.filter(function (elm) {
						var datum = new Date(elm.nom);
						return (datum.getHours() === 11 || datum.getHours() === 13);
					});
					if (idx.length > 0) {
						//Du coup, ça sera celle entre les deux
						idxUtile = idx[(Math.ceil(idx.length / 2) - 1)].index;
					} else {
						//On prend celle du milieu
						idxUtile = dates[(Math.ceil(dates.length / 2) - 1)].index;
					}
				}

				//console.log('Verso', verso);
				if (!verso) {
					visi.versusPhotos.vice = this.catalogue.files[idxUtile];
					document.querySelector('.recto').innerHTML = visi.calendrier.formatDate(visi.versusPhotos.vice.nom.substr(0, 10)); //Pas besoin d'afficher l'heure ici
					document.querySelector('.recto').setAttribute('data-date', visi.versusPhotos.vice.nom);
					if (!this.versa) {
						this.currIndex = idxUtile;
						this.currPhoto = visi.versusPhotos.vice;
						this.affichePhoto();
					}
				} else {
					visi.versusPhotos.versa = this.catalogue.files[idxUtile];
					document.querySelector('.verso').innerHTML = visi.calendrier.formatDate(visi.versusPhotos.versa.nom.substr(0, 10)); //Pas besoin d'afficher l'heure ici
					document.querySelector('.verso').setAttribute('data-date', visi.versusPhotos.versa.nom);
					if (this.versa) {
						this.currIndex = idxUtile;
						this.currPhoto = visi.versusPhotos.versa;
						this.affichePhoto();
					}
				}
				//Affichage appelé, masquage du calendrier
				//this.popThing(false);
				//Et on est ok
				return true;
			} else {
				nodeVue = document.querySelector('.grilleBody');
				//Vidage de la grille
				while (nodeVue.firstChild) {
					nodeVue.removeChild(nodeVue.firstChild);
				}
				//Affichage de la grille
				document.querySelector('.taskbar .date').innerHTML = visi.calendrier.formatDate(date);
				dates.forEach(function (elm) {
					var figure = document.createElement('figure'),
						img = document.createElement('img'),
						caption = document.createElement('figcaption');
					figure.classList.add('grilleElement');
					figure.dataset.index = elm.index;
					img.src = visi.catalogue.params.dossier + '/mini/' + elm.fichier;
					img.alt = elm.nom;
					caption.innerHTML = elm.nom.split(' ')[1];
					figure.appendChild(img);
					figure.appendChild(caption);
					document.querySelector('.grilleBody').appendChild(figure);
				});
				//Et on s'affiche
				nodeVue = document.querySelector('.courante');
				this.popThing({
					target: {
						id: 'cell',
						offsetParent: {
							offsetTop: nodeVue.offsetParent.offsetTop
						},
						offsetTop: nodeVue.offsetTop,
						offsetLeft: nodeVue.offsetLeft,
						offsetWidth: nodeVue.offsetWidth,
						offsetHeight: nodeVue.offsetHeight
					}
				});
			}
		}
		return false;
	},
	getPhoto: function (sens) {
		'use strict';
		var visi = this;
		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
			if (typeof (sens) === 'object') {
				sens = sens.target.getAttribute('data-sens');
			}
		}
		//Si on est encore en train de charger/tracer la photo, ne pas passer à la suite
		if (!visi.endDraw) {
			return false;
		} else {
			if (sens === 'prev') {
				if (visi.currIndex > 0) {
					visi.currIndex = visi.currIndex - 1;
				}
			} else {
				visi.currIndex = visi.currIndex + 1;
				if (visi.currIndex === visi.catalogue.files.length) {
					visi.currIndex = visi.currIndex - 1;
				}
			}
			visi.currPhoto = visi.catalogue.files[visi.currIndex];
			/* Rester zoomé !
			visi.currZoom = 0;
			visi.deplacement = {
				top: 0,
				left: 0
			};
			*/
			// Définition de la courante
			if (visi.versa) {
				visi.canvas.cible.setAttribute('data-versus', JSON.stringify(visi.currPhoto));
				//Pas besoin d'afficher l'heure ici
				document.querySelector('.verso').innerHTML = visi.calendrier.formatDate(visi.currPhoto.nom.substring(0, 10));
				document.querySelector('.verso').setAttribute('data-date', visi.currPhoto.nom);
			} else {
				visi.canvas.cible.setAttribute('data-current', JSON.stringify(visi.currPhoto));
				// Pas besoin d'afficher l'heure ici
				document.querySelector('.recto').innerHTML = visi.calendrier.formatDate(visi.currPhoto.nom.substring(0, 10));
				document.querySelector('.recto').setAttribute('data-date', visi.currPhoto.nom);
			}
			// Lancer l'affichage de la photo
			visi.affichePhoto();

			return visi.currPhoto.nom;
		}
	},
	setPhoto: function (info) {
		'use strict';
		var visi = this;
		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}

		if (typeof (info.index) !== 'undefined') {
			visi.currIndex = parseInt(info.index, 10);
		}

		visi.currPhoto = visi.catalogue.files[visi.currIndex];
		visi.canvas.cible.setAttribute('data-current', JSON.stringify(visi.currPhoto));
		visi.affichePhoto();

		return visi;
	},
	/******* Gestion décompte ***********/
	setDecompte: function () {
		'use strict';
		var visi = this,
			date = new Date(),
			dateFuture = new Date(visi.decompte.date),
			diffDate = 0;

		if (dateFuture > date) {
			document.querySelector('.decompte').classList.remove('hidden');
			document.querySelector('.decompte-libelle').innerHTML = visi.decompte.libelle;
			//Faire la diff en millisecondes
			diffDate = dateFuture.getTime() - date.getTime();
			//Faire la diff en S/M/H/J
			diffDate = Math.floor((((diffDate / 1000) / 60) / 60) / 24) + 1;
			document.querySelector('.decompte-jours').innerHTML = diffDate;
		}

		return visi;
	},
	/******* Gestion maintenance ***********/
	setMaintenance: function () {
		'use strict';
		var visi = this;

		if (visi.maintenance !== '') {
			document.querySelector('.maintenance').classList.remove('hidden');
			document.querySelector('.maintenance-libelle').innerHTML = visi.maintenance;
		}

		return visi;
	},
	/****** Fonctions boutons **************/
	refresh: function () {
		'use strict';
		var visi = this;
		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}
		visi.activity();
		return fetch('config.json?rand=' + new Date().getTime(), {
			credentials: 'include'
		})
			.then(function (reponse) {
				return reponse.json();
			})
			.then(function (json) {
				json.config.makeCron = true;
				return visi.setConfig(json.config);
			})
			.then(function () {
				document.querySelector('.vues select').value = visi.catalogue.params.fichier;
				return visi.getVue(visi.catalogue.params.fichier);
			})
			.catch(function (err) {
				console.log(err);
			});
	},
	switchPhoto: function () {
		'use strict';
		var visi = this,
			versus = {
				fichier: '',
				nom: '',
				index: 0
			};
		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}
		//Et vice-versa
		visi.versa = !visi.versa;

		if (visi.versa) {
			versus = visi.versusPhotos.versa;
			/*document.querySelector('.recto').classList.remove('btnActive');
			document.querySelector('.verso').classList.add('btnActive');*/
		} else {
			versus = visi.versusPhotos.vice;
			/*document.querySelector('.verso').classList.remove('btnActive');
			document.querySelector('.recto').classList.add('btnActive');*/
		}

		visi.currPhoto = versus;
		/*visi.currIndex = visi.catalogue.files.filter(function (file, i) {
			visi.catalogue.files[i].index = i;
			return (file.fichier === visi.currPhoto.fichier);
		});
		visi.currIndex = visi.currIndex[0].index;*/
		visi.currIndex = versus.index;

		visi.affichePhoto();

		return visi;
	},
	comparePhoto: function (e) {
		var visi = this,
			currDossier,
			beforePhoto,
			afterPhoto,
			pct = 0.5,
			coupeur = document.querySelector('.coupeur'),
			bouton = document.querySelector('.comparer');

		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}
		visi.activity(true);
		//console.log(e);
		beforePhoto = visi.versusPhotos.versa;
		afterPhoto = visi.versusPhotos.vice;

		if (e.type === 'tap') {
			//Le passage des 3 états : actif, inversé, inactif
			if (coupeur.classList.contains('hidden')) {
				//Le replacer correctement au centre
				coupeur.style.left = ((visi.canvas.dimensions.canvas.width / 2) - (coupeur.clientWidth / 2)) + 'px';
				pct = (((visi.canvas.dimensions.canvas.width / 2) - visi.canvas.coords.x) / visi.canvas.dimensions.redim.width);
				coupeur.classList.remove('hidden');
				bouton.classList.remove('flip');
			} else if (bouton.classList.contains('flip') === false) {
				bouton.classList.add('flip');
				coupeur.style.left = ((visi.canvas.dimensions.canvas.width / 2) - (coupeur.clientWidth / 2)) + 'px';
				pct = (((visi.canvas.dimensions.canvas.width / 2) - visi.canvas.coords.x) / visi.canvas.dimensions.redim.width);
				beforePhoto = visi.versusPhotos.vice;
				afterPhoto = visi.versusPhotos.versa;
			} else if (bouton.classList.contains('flip')) {
				bouton.classList.remove('flip');
				coupeur.classList.add('hidden');
				afterPhoto = visi.versusPhotos.versa;
			}
		}
		if (e.type === 'dragmove') {
			if (bouton.classList.contains('flip') === true) {
				beforePhoto = visi.versusPhotos.vice;
				afterPhoto = visi.versusPhotos.versa;
			}
			//Le déplacement du séparateur
			coupeur.style.left = (e.clientX - (coupeur.clientWidth / 2)) + 'px';
			if (e.clientX > (visi.canvas.dimensions.canvas.width - coupeur.clientWidth)) {
				//On ne déborde pas
				coupeur.style.left = (visi.canvas.dimensions.canvas.width - coupeur.clientWidth) + 'px';
			}
			//Marche bien quand on est AU MILIEU
			//pct = ((e.clientX + (visi.canvas.debordements.calcHorizontal / 2)) / visi.canvas.dimensions.redim.width);
			//Marchera mieux quelque soit la position
			pct = ((e.clientX - visi.canvas.coords.x) / visi.canvas.dimensions.redim.width);
			//console.log(pct, e.clientX);
			if (pct < 0) {
				pct = 0;
			} else if (pct > 1) {
				pct = 1;
			}
		}

		currDossier = visi.catalogue.params.dossier + '/';
		/*if (visi.catalogue.params.mini == 'true') {
			// Valider les params de mini
			if (visi.validateParamsMini(visi.catalogue.params.params_miniatures)) {
				// Dire que les images sont dans /mini
				currDossier = visi.catalogue.params.dossier + '/mini/';
			}
		}*/
		beforePhoto = currDossier + beforePhoto.fichier;
		afterPhoto = currDossier + afterPhoto.fichier;

		return visi.canvas.compare(beforePhoto, afterPhoto, pct).then(function () {
			return visi.activity(false);
		});
	},
	kbShortcut: function (e) {
		'use strict';
		var visi = this;
		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}
		//console.log(e);
		switch (e.keyCode) {
			case 39: //Fleche droite
				visi.getPhoto('next');
				break;
			case 37: //Fleche gauche
				visi.getPhoto('prev');
				break;
			case 38: //Fleche haut
			case 107: //+
			case 187: //maj+=
				visi.zoomTo({
					target: {
						dataset: {
							sens: 'plus'
						}
					}
				});
				break;
			case 40: //Fleche bas
			case 109: //- verrnum
			case 54: //-
				visi.zoomTo({
					target: {
						dataset: {
							sens: 'minus'
						}
					}
				});
				break;
			case 32: //Espace
				visi.openCurrent({
					type: 'doubletap',
					//La position 0,0 c'est le milieu du canvas
					clientX: (visi.canvas.dimensions.canvas.width / 2),
					clientY: (visi.canvas.dimensions.canvas.height / 2)
				});
				break;
		}
	},
	mailTo: function () {
		'use strict';
		var visi = this;
		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}

		window.location.href = encodeURI('mailto:' + visi.config.mail + '?subject=Information sur "' + visi.config.nom + '"');
		return true;
	},
	showDesc: function (content) {
		'use strict';
		var bg = document.createElement('div'),
			corps = document.createElement('div'),
			close = document.createElement('div'),
			visi = this;
		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}
		if (typeof (content) === 'object') {
			content = visi.config.desc_proj;
		}

		if (content === '') {
			return false;
		}

		bg.classList.add('pop-opaq');
		corps.classList.add('pop-body');
		close.classList.add('pop-close');

		corps.insertAdjacentHTML('beforeEnd', content);
		bg.appendChild(corps);
		bg.appendChild(close);
		document.body.appendChild(bg);
		setTimeout(function () {
			bg.classList.add('fadeIn');

			//Se fermer soit au close, soit au clic dans le fond
			close.addEventListener('click', function () {
				bg.classList.remove('fadeIn');
				bg.addEventListener('transitionend', function () {
					bg.remove();
				});
			});
			bg.addEventListener('click', function () {
				bg.classList.remove('fadeIn');
				bg.addEventListener('transitionend', function () {
					bg.remove();
				});
			});
		}, 50);
	},
	showHelp: function () {
		'use strict';
		var visi = this;
		if (!(visi instanceof Visionneuse)) {
			visi = maVisionneuse;
		}
		return visi.showDesc(visi.config.help_proj);
	}
};

/*********** Classe Canvas ***********/
Canvas = function (elm) {
	'use strict';
	if (typeof (elm) !== 'undefined') {
		this.cible = elm;
		this.ctx = elm.getContext('2d');
	}

	return this;
};
Canvas.prototype = {
	//Un <canvas>
	cible: null,
	//Son getContext() ('2d') de préférence
	ctx: null,
	//L'image qui sera écrite dedans
	image: new Image(),
	//L'orientation de l'image
	orientation: null,
	//L'orientation du canvas (et à priori de l'écran)
	orientationEcran: null,
	//Coordonnnées pour le placement dans Canvas
	coords: {
		x: 0,
		y: 0
	},
	//Espace en trop par rapport à l'adaptation
	debordements: {
		vertical: 0,
		horizontal: 0,
		calcVertical: 0,
		calcHorizontal: 0
	},
	//Dimensions générales
	dimensions: {
		canvas: {
			width: 0,
			height: 0
		},
		image: {
			width: 0,
			height: 0
		},
		redim: {
			width: 0,
			height: 0
		}
	},
	setDimension: function (ajustement) {
		'use strict';

		if (typeof (ajustement) === 'undefined') {
			ajustement = 'auto';
		}

		//MAJ des dimensions du canvas
		this.dimensions.canvas.width = this.cible.width;
		this.dimensions.canvas.height = this.cible.height;

		//MAJ des dimensions de l'image
		if (this.image.src !== '') {
			this.dimensions.image.width = this.image.width;
			this.dimensions.image.height = this.image.height;

			//console.log(ajustement);
			if (ajustement === 'auto') {
				//MAJ de l'orientation
				if (this.dimensions.image.width < this.dimensions.image.height) {
					this.orientation = 'portrait';
				} else {
					//Oui, même si c'est format carré, c'est un paysage
					this.orientation = 'paysage';
				}
			} else {
				this.orientation = ajustement;
			}
		}

		//MAJ des dimensions utilisables
		this.debordements.horizontal = this.dimensions.image.width - this.dimensions.canvas.width;
		this.debordements.vertical = this.dimensions.image.height - this.dimensions.canvas.height;

		//MAJ de la dimension de l'écran
		if (this.cible.width < this.cible.height) {
			this.orientationEcran = 'portrait';
		} else {
			//Oui, même si c'est format carré, c'est un paysage, encore.
			this.orientationEcran = 'paysage';
		}

		return this;
	},
	move: function (zoom, deplacement) {
		'use strict';
		//console.log('ctx_move');
		//console.log(zoom, deplacement);
		return this.redim(zoom)
			.then(function (canvas) {
				canvas.coords.x = canvas.coords.x + deplacement.left;
				canvas.coords.y = canvas.coords.y + deplacement.top;
				return canvas;
			})
			.then(function (canvas) {
				return canvas.draw(true);
			});
	},
	redim: function (zoom, centre) {
		'use strict';
		//console.log('ctx_redim');
		if (typeof (zoom) === 'undefined') {
			zoom = 0;
		}
		if (typeof (centre) === 'undefined') {
			centre = {
				x: 0,
				y: 0
			};
		}

		this.dimensions.redim.width = this.dimensions.image.width;
		this.dimensions.redim.height = this.dimensions.image.height;
		//Recalcul des dimensions en fonction du zoom
		//Zoom 0 = image entièrement visible
		if (this.orientation === 'paysage') {
			if (this.orientationEcran === 'paysage') {
				//Adapter en hauteur
				//nouvelle hauteur = ancienne hauteur - (débordement possible * zoom)(x% de l'espace disponible)
				this.dimensions.redim.height = this.dimensions.image.height - this.debordements.vertical * (1 - (zoom / 100));
				//La largeur est adaptative, règle de 3 loulou !
				//a = image.height, b = image.width, c = redim.height, d = redim.width
				//a * d = c * b
				//d = c*b / a
				this.dimensions.redim.width = (this.dimensions.redim.height * this.dimensions.image.width) / this.dimensions.image.height;
				if (zoom === 0 && this.dimensions.redim.width > this.dimensions.canvas.width) {
					console.log('Finally larger !');
					//Finalement, non, c'est plus large que prévu
					this.dimensions.redim.width = this.dimensions.image.width - this.debordements.horizontal * (1 - (zoom / 100));
					//Et donc la hauteur
					this.dimensions.redim.height = (this.dimensions.redim.width * this.dimensions.image.height) / this.dimensions.image.width;
				}
			} else {
				//Adapter en largeur
				this.dimensions.redim.width = this.dimensions.image.width - this.debordements.horizontal * (1 - (zoom / 100));
				//Et donc la hauteur
				this.dimensions.redim.height = (this.dimensions.redim.width * this.dimensions.image.height) / this.dimensions.image.width;
				if (zoom === 0 && this.dimensions.redim.height > this.dimensions.canvas.height) {
					console.log('Finally hotter !');
					//Finalement, non, c'est plus haut que prévu
					this.dimensions.redim.height = this.dimensions.image.height - this.debordements.vertical * (1 - (zoom / 100));
					//Et donc la largeur
					this.dimensions.redim.width = (this.dimensions.redim.height * this.dimensions.image.width) / this.dimensions.image.height;
				}
			}
		} else if (this.orientation === 'portrait') {
			if (this.orientationEcran === 'portrait') {
				//Adapter en largeur
				this.dimensions.redim.width = this.dimensions.image.width - this.debordements.horizontal * (1 - (zoom / 100));
				//Et donc la hauteur
				this.dimensions.redim.height = (this.dimensions.redim.width * this.dimensions.image.height) / this.dimensions.image.width;
				if (zoom === 0 && this.dimensions.redim.height > this.dimensions.canvas.height) {
					console.log('Finally Otter !');
					//Finalement, non, c'est plus haut que prévu
					this.dimensions.redim.height = this.dimensions.image.height - this.debordements.vertical * (1 - (zoom / 100));
					//Et donc la largeur
					this.dimensions.redim.width = (this.dimensions.redim.height * this.dimensions.image.width) / this.dimensions.image.height;
				}
			} else {
				//Adapter en hauteur
				this.dimensions.redim.height = this.dimensions.image.height - this.debordements.vertical * (1 - (zoom / 100));
				//Et donc la largeur
				this.dimensions.redim.width = (this.dimensions.redim.height * this.dimensions.image.width) / this.dimensions.image.height;
				if (zoom === 0 && this.dimensions.redim.width > this.dimensions.canvas.width) {
					console.log('Finally fatter !');
					//Finalement, non, c'est plus large que prévu
					this.dimensions.redim.width = this.dimensions.image.width - this.debordements.horizontal * (1 - (zoom / 100));
					//Et donc la hauteur
					this.dimensions.redim.height = (this.dimensions.redim.width * this.dimensions.image.height) / this.dimensions.image.width;
				}
			}
		} else if (this.orientation === 'largeur') {
			//Adapter en largeur
			this.dimensions.redim.width = this.dimensions.image.width - this.debordements.horizontal * (1 - (zoom / 100));
			//Et donc la hauteur
			this.dimensions.redim.height = (this.dimensions.redim.width * this.dimensions.image.height) / this.dimensions.image.width;
		} else if (this.orientation === 'hauteur') {
			//Adapter en hauteur
			this.dimensions.redim.height = this.dimensions.image.height - this.debordements.vertical * (1 - (zoom / 100));
			//Et donc la largeur
			this.dimensions.redim.width = (this.dimensions.redim.height * this.dimensions.image.width) / this.dimensions.image.height;
		}

		//Recalcul centrage
		this.coords.x = (this.dimensions.canvas.width - this.dimensions.redim.width) / 2;
		this.coords.y = (this.dimensions.canvas.height - this.dimensions.redim.height) / 2;

		//Soit-disant, utiliser des pixels ronds est plus performant (moins de subpix AA) si pas d'accélération GPU
		this.dimensions.redim.width = Math.round(this.dimensions.redim.width);
		this.dimensions.redim.height = Math.round(this.dimensions.redim.height);
		this.coords.x = Math.round(this.coords.x);
		this.coords.y = Math.round(this.coords.y);

		//Recalcul débordements
		this.debordements.calcHorizontal = this.dimensions.redim.width - this.dimensions.canvas.width;
		this.debordements.calcVertical = this.dimensions.redim.height - this.dimensions.canvas.height;

		return Promise.resolve(this);
	},
	draw: function (rapide) {
		'use strict';
		var virtCanvas = {
				stepper: 0,
				height: 0,
				width: 0,
				subHeight: 0,
				subWidth: 0,
				centerHeight: 0,
				centerWidth: 0
			},
			canv2 = document.createElement('canvas'),
			ctx2 = canv2.getContext('2d'),
			zoom = 0;

		//Effacement du canvas, si image tracée plus petit
		if (this.dimensions.redim.width < this.dimensions.canvas.width || this.dimensions.redim.height < this.dimensions.canvas.height) {
			this.clear();
		}

		//console.log('Rapide ?', rapide);
		rapide = true; //JE SUIS RAPIDE AHAHAHAH
		if (!rapide) {
			//3 étapes de redimensionnement pour plus de qualité
			console.log(this.orientation);
			if (this.orientation === 'portrait') {
				//Partant du principe que l'image est plus grande que le canvas
				virtCanvas.height = this.dimensions.canvas.height + ((this.dimensions.image.height - this.dimensions.canvas.height) * (zoom / 100));
				//console.log('New Canvas Height : ' + virtCanvas.height);
				virtCanvas.stepper = (this.dimensions.image.height - virtCanvas.height) / 3;

				virtCanvas.height = Math.max(1, Math.floor(this.dimensions.image.height - virtCanvas.stepper));
				virtCanvas.width = Math.max(1, Math.floor((this.dimensions.image.width * virtCanvas.height) / this.dimensions.image.height));
				virtCanvas.subHeight = Math.max(1, Math.floor(virtCanvas.height - virtCanvas.stepper));
				virtCanvas.subWidth = Math.max(1, Math.floor((virtCanvas.width * virtCanvas.subHeight) / virtCanvas.height));
				/*console.log('w1:' + imgW);
				console.log('h1:' + imgH);
				console.log('w2:' + sw);
				console.log('h2:' + sh);*/
				canv2.width = virtCanvas.width;
				canv2.height = virtCanvas.height;

				ctx2.drawImage(this.image, 0, 0, virtCanvas.width, virtCanvas.height);
				//document.body.appendChild(canv2);
				ctx2.drawImage(canv2, 0, 0, virtCanvas.width, virtCanvas.height, 0, 0, virtCanvas.subWidth, virtCanvas.subHeight);
				//document.body.appendChild(canv2);
				virtCanvas.width = virtCanvas.subWidth;
				virtCanvas.height = virtCanvas.subHeight;
				//console.log('w3:' + virtCanvas.width);
				//console.log('h3:' + virtCanvas.height);
				virtCanvas.subHeight = virtCanvas.subHeight - virtCanvas.stepper;
				virtCanvas.subWidth = Math.max(1, Math.floor((virtCanvas.width * virtCanvas.subHeight) / virtCanvas.height));
				//console.log('w4:' + virtCanvas.subWidth);
				//console.log('h4:' + virtCanvas.subHeight);
			} else if (this.orientation === 'paysage') {
				virtCanvas.width = this.dimensions.canvas.width + ((this.dimensions.image.width - virtCanvas.width) * (zoom / 100));
				//console.log('New Canvas Width : ' + virtCanvas.width);
				virtCanvas.stepper = (this.dimensions.image.width - virtCanvas.width) / 3;
				//let's draw it to an in-memory canvas at some scale,
				//then scale again,
				//then scale again.
				//The result is a correct scale image, but we use three steps:
				virtCanvas.width = Math.max(1, Math.floor(this.dimensions.image.width - virtCanvas.stepper));
				virtCanvas.height = Math.max(1, Math.floor((this.dimensions.image.height * virtCanvas.width) / this.dimensions.image.width));
				virtCanvas.subWidth = Math.max(1, Math.floor(virtCanvas.width - virtCanvas.stepper));
				virtCanvas.subHeight = Math.max(1, Math.floor((virtCanvas.height * virtCanvas.subWidth) / virtCanvas.width));
				/*console.log('w1:' + this.dimensions.image.width);
				console.log('h1:' + this.dimensions.image.height);
				console.log('w2:' + virtCanvas.subWidth);
				console.log('h2:' + virtCanvas.subHeight);*/
				canv2.width = virtCanvas.width;
				canv2.height = virtCanvas.height;

				ctx2.drawImage(this.image, 0, 0, virtCanvas.width, virtCanvas.height);
				ctx2.drawImage(canv2, 0, 0, virtCanvas.width, virtCanvas.height, 0, 0, virtCanvas.subWidth, virtCanvas.subHeight);
				virtCanvas.width = virtCanvas.subWidth;
				virtCanvas.height = virtCanvas.subHeight;
				//console.log('w3:' + virtCanvas.width);
				//console.log('h3:' + virtCanvas.height);
				virtCanvas.subWidth = virtCanvas.subWidth - virtCanvas.stepper;
				virtCanvas.subHeight = Math.max(1, Math.floor((virtCanvas.height * virtCanvas.subWidth) / virtCanvas.width));
				//console.log('w4:' + virtCanvas.subWidth);
				//console.log('h4:' + virtCanvas.subHeight);
			} else {
				//On relance en paysage, parce que.
				this.orientation = 'paysage';
				return this.draw(rapide);
			}

			//Final Draw
			ctx2.drawImage(canv2, 0, 0, virtCanvas.width, virtCanvas.height, 0, 0, virtCanvas.subWidth, virtCanvas.subHeight);
			//var debordH = Math.ceil((virtCanvas.subHeight - this.dimensions.canvas.height) / 2);
			//var debordW = Math.ceil((virtCanvas.subWidth - this.dimensions.canvas.width) / 2);
			//And maintenant, on calcule le centrage
			// = hauteur canvas/2 - hauteur img/2
			virtCanvas.centerHeight = Math.floor((this.dimensions.canvas.height / 2) - (virtCanvas.subHeight / 2));
			//centerH = Math.max(0, Math.abs(centerH));
			virtCanvas.centerHeight = virtCanvas.centerHeight + this.coords.y;
			// = largeur canvas/2 - hauteur img/2
			virtCanvas.centerWidth = Math.floor((this.dimensions.canvas.width / 2) - (virtCanvas.subWidth / 2));
			//centerW = Math.max(0, Math.abs(centerW));
			virtCanvas.centerWidth = virtCanvas.centerWidth + this.coords.x;

			/*console.log('Affiche Zoom: ' + zoom);
			console.log('cw:' + virtCanvas.centerWidth);
			console.log('ch:' + virtCanvas.centerHeight);*/
		}

		if (rapide) {
			//console.log(this.coords, this.dimensions.redim);
			this.ctx.drawImage(this.image, this.coords.x, this.coords.y, this.dimensions.redim.width, this.dimensions.redim.height);
		} else {
			console.log(virtCanvas);
			this.ctx.drawImage(canv2, this.coords.x, this.coords.y, virtCanvas.subWidth, virtCanvas.subHeight, virtCanvas.centerWidth, virtCanvas.centerHeight, virtCanvas.subWidth, virtCanvas.subHeight);
		}

		return Promise.resolve(this);
	},
	clear: function () {
		return this.ctx.clearRect(0, 0, this.dimensions.canvas.width, this.dimensions.canvas.height);
	},
	//Très fortement inspirée par http://jsfiddle.net/davidhong/WkM5z/
	compare: function (after, before, splitPct) {
		var cvs = this,
			afterImg = new Image(),
			afterLoad,
			beforeImg = new Image,
			beforeLoad;

		return new Promise(function (superResolve, superReject) {
			afterLoad = new Promise(function (resolve, reject) {
				afterImg.onload = function () {
					resolve();
				};
				afterImg.onerror = function () {
					reject();
				};
				afterImg.src = after;
			});
			beforeLoad = new Promise(function (resolve, reject) {
				beforeImg.onload = function () {
					resolve();
				};
				beforeImg.onerror = function () {
					reject();
				};
				beforeImg.src = before;
			});

			Promise.all([afterLoad, beforeLoad])
				.then(function () {
					//Nettoyer
					cvs.clear();
					//Première image, à 100%
					cvs.ctx.drawImage(afterImg, cvs.coords.x, cvs.coords.y, cvs.dimensions.redim.width, cvs.dimensions.redim.height);
					if (splitPct > 0) {
						//Deuxième image, à position
						// console.log(splitPct, (cvs.dimensions.image.width * splitPct), (cvs.dimensions.redim.width * splitPct));
						cvs.ctx.drawImage(beforeImg, 0, 0, (cvs.dimensions.image.width * splitPct), cvs.dimensions.image.height, cvs.coords.x, cvs.coords.y, (cvs.dimensions.redim.width * splitPct), cvs.dimensions.redim.height);
					}

					superResolve(true);
				})
				.catch(function (err) {
					console.log(err);
					superReject(false);
				});
		});
	}
};

/*********** Classe Calendrier ***********/
Calendrier = function (date) {
	'use strict';
	var cal = this;

	if (typeof (date) !== 'undefined') {
		cal.setFullDate(date);
	}
	cal.boutons = document.querySelectorAll('.control-calendrier button');
	cal.corps = document.getElementById('table_calendrier');
	cal.grille = document.querySelector('.grille');

	interact('.taskbar-title button').on('tap', cal.setMonth);
	interact('#container_calendrier')
		.draggable({
			inertia: true,
			restrict: {
				/*restriction: {
					//top: parseFloat(window.getComputedStyle(document.querySelector('header')).height, 10),
					top: 0,
					//bottom: viewportSize.height - parseFloat(window.getComputedStyle(document.querySelector('footer')).height, 10),
					bottom: viewportSize.height,
					left: 0,
					right: viewportSize.width
				},*/ //Ne se met pas à jour en resize :(
				restriction: 'body', //Mais ça, si.
				elementRect: {
					left: 0,
					right: 1,
					top: 0,
					bottom: 1
				}
			},
			allowFrom: '.move'
		})
		.on('dragmove', function (event) {
			var target = event.target,
				// keep the dragged position in the data-x/data-y attributes
				x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
				y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

			// translate the element
			target.style.transform = 'translate(' + x + 'px, ' + y + 'px)';

			// update the posiion attributes
			target.setAttribute('data-x', x);
			target.setAttribute('data-y', y);
		});
	interact('#table_calendrier')
		.on('hold', function (e) {
			if (e.target.tagName === 'DIV') {
				e.target = e.target.parentNode;
			}
			if (e.target.tagName === 'TD') {
				if (typeof (maVisionneuse.popFor.classList) !== 'undefined' && (maVisionneuse.popFor.classList.contains('recto') || maVisionneuse.popFor.classList.contains('verso'))) {
					maVisionneuse.getDay(e.target.getAttribute('data-date'), true, maVisionneuse.popFor.classList.contains('verso'));
				} else {
					maVisionneuse.getDay(e.target.getAttribute('data-date'));
				}
			}
		})
		.on('tap', function (e) {
			if (e.target.tagName === 'DIV') {
				e.target = e.target.parentNode;
			}
			//console.log('typeof', typeof (maVisionneuse.popFor.classList));
			//console.log('viceVersaille', (maVisionneuse.popFor.classList.contains('recto') || maVisionneuse.popFor.classList.contains('verso')));
			if (e.target.tagName === 'TD') {
				if (typeof (maVisionneuse.popFor.classList) !== 'undefined' && (maVisionneuse.popFor.classList.contains('recto') || maVisionneuse.popFor.classList.contains('verso'))) {
					maVisionneuse.getDay(e.target.getAttribute('data-date'), true, maVisionneuse.popFor.classList.contains('verso'));
				} else {
					maVisionneuse.getDay(e.target.getAttribute('data-date'), true, maVisionneuse.versa);
				}
			}
		});
	interact('.grilleBody').on('tap', function (e) {
		if (e.target.tagName === 'IMG') {
			e.target = e.target.parentNode;
		} else if (e.target.tagName === 'FIGCAPTION') {
			e.target = e.target.parentNode;
		}
		if (e.target.tagName === 'FIGURE') {
			maVisionneuse.setPhoto({
				index: e.target.dataset.index
			});
			//Fermer la grille
			//maVisionneuse.popThing(false);
		}
	});
	interact('.taskbar .back').on('tap', function () {
		maVisionneuse.popThing({
			target: document.querySelector('.courante')
		});
	});
	interact('.taskbar .close').on('tap', function () {
		maVisionneuse.popThing(false);
	});

	cal.renderMonth();

	return cal;
};
Calendrier.prototype = {
	basicDate: new Date(),
	borneMin: new Date(),
	borneMax: new Date(),
	openedFor: null,
	mois: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
	nbJours: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
	boutons: null,
	corps: null,
	grille: null,
	setFullDate: function (date) {
		'use strict';
		//console.log(date);
		this.basicDate = new Date(date);

		//Trouver le 1er jour du mois par magie JS
		if (this.basicDate.getDate() !== 1) {
			this.basicDate.setDate(1);
		}
		return this;
	},
	setMonth: function (sens) {
		'use strict';
		var calendrier = this;
		if (!(calendrier instanceof Calendrier)) {
			calendrier = maVisionneuse.calendrier;
			sens = sens.target.getAttribute('data-sens');
		}
		if (sens === 'prev') {
			if (calendrier.basicDate.getMonth() === 0) {
				//Magie !
				calendrier.basicDate.setMonth(-1);
			} else {
				calendrier.basicDate.setMonth(calendrier.basicDate.getMonth() - 1);
			}
		} else {
			calendrier.basicDate.setMonth(calendrier.basicDate.getMonth() + 1);
		}
		calendrier.renderMonth();
		calendrier.countPhoto(maVisionneuse);
		return true;
	},
	renderMonth: function () {
		'use strict';
		var i = 0,
			j = 0,
			n = 0,
			k = 0,
			m = 0,
			jourSemaine = (this.basicDate.getDay() - 1),
			tablo = this.corps.querySelectorAll('tbody tr'),
			classMois = 'precedent',
			formatDate = '';
		//Années biplextiles
		if (new Date(this.basicDate.getFullYear(), 1, 29).getMonth() === 1) {
			this.nbJours[1] = 29;
		} else {
			this.nbJours[1] = 28;
		}
		//Dimanche étant le premier des derniers jours de la semaine
		if (jourSemaine === -1) {
			jourSemaine = 6;
		}
		//On affiche le libellé
		document.querySelector('.taskbar .date').innerHTML = this.mois[this.basicDate.getMonth()] + ' ' + this.basicDate.getFullYear();

		//On grise ou pas les boutons suivants/précédents
		if (this.canGo('min')) {
			this.corps.parentNode.querySelectorAll('button')[0].classList.remove('disabled');
		} else {
			this.corps.parentNode.querySelectorAll('button')[0].classList.add('disabled');
		}
		if (this.canGo('max')) {
			this.corps.parentNode.querySelectorAll('button')[1].classList.remove('disabled');
		} else {
			this.corps.parentNode.querySelectorAll('button')[1].classList.add('disabled');
		}

		//Et là on fait le reste du calendrier
		for (i = 0, n = tablo.length; i < n; i = i + 1) {
			for (k = 0, m = tablo.item(i).children.length; k < m; k = k + 1) {
				tablo.item(i).children[k].className = '';
				//Donc, passage sur la première ligne pour le premier jour du mois
				if (i === 0) {
					if (k === jourSemaine) {
						//Youpi, le 1er jour
						j = 1;
						classMois = 'courant';
					}
				} else {
					//Là, on est au prochain mois
					if (j > this.nbJours[this.basicDate.getMonth()]) {
						//Donc on repart au jour 1
						j = 1;
						classMois = 'prochain';
					}
				}

				tablo.item(i).children[k].children[0].innerHTML = j;
				tablo.item(i).children[k].classList.add(classMois);
				//Supprimer badge si badge
				if (tablo.item(i).children[k].children.length > 1) {
					tablo.item(i).children[k].children[1].remove();
				}
				if (classMois === 'courant') {
					formatDate = this.basicDate.getFullYear() + '/' + (this.basicDate.getMonth() + 1);
				} else {
					if ((this.basicDate.getMonth() + 2) > 11) {
						//C'est janvier année suivante
						formatDate = (this.basicDate.getFullYear() + 1) + '/01';
					} else {
						//C'est juste le mois suivant
						formatDate = this.basicDate.getFullYear() + '/' + (this.basicDate.getMonth() + 2);

					}
				}
				if (formatDate.length < 7) {
					formatDate = formatDate.substr(0, 5) + '0' + formatDate.substr(-1);
				}
				if (j.toString().length === 1) {
					formatDate = formatDate + '/0' + j;
				} else {
					formatDate = formatDate + '/' + j;
				}
				tablo.item(i).children[k].setAttribute('data-date', formatDate);
				j = j + 1;
			}
		}
		//Et pour les précédents, on fait les jours à l'envers
		tablo = this.corps.querySelectorAll('.precedent');
		m = (this.basicDate.getMonth() - 1);
		if (m === -1) {
			//C'est l'année précédente
			m = 11;
			formatDate = (this.basicDate.getFullYear() - 1);
		} else {
			//C'est juste le mois précédent
			formatDate = this.basicDate.getFullYear();
		}
		formatDate = formatDate + '/' + (m + 1) + '/';
		if (formatDate.length < 8) {
			formatDate = formatDate.substr(0, 5) + '0' + formatDate.substr(-2);
		}
		m = this.nbJours[m];
		for (i = (tablo.length - 1), n = 0; i >= n; i = i - 1) {
			tablo.item(i).children[0].innerHTML = m;
			tablo.item(i).setAttribute('data-date', formatDate + m);
			//Supprimer badge si badge
			if (tablo.item(i).children.length > 1) {
				tablo.item(i).children[1].remove();
			}
			m = m - 1;
		}

		return true;
	},
	canGo: function (sens) {
		'use strict';
		var borne,
			heCan = false;

		if (sens === 'min') {
			borne = this.borneMin;

			if (borne.getFullYear() < this.basicDate.getFullYear()) {
				heCan = true;
			} else {
				if (borne.getFullYear() === this.basicDate.getFullYear()) {
					if (borne.getMonth() < this.basicDate.getMonth()) {
						heCan = true;
					}
				}
			}
		} else {
			borne = this.borneMax;

			if (borne.getFullYear() > this.basicDate.getFullYear()) {
				heCan = true;
			} else {
				if (borne.getFullYear() === this.basicDate.getFullYear()) {
					if (borne.getMonth() > this.basicDate.getMonth()) {
						heCan = true;
					}
				}
			}
		}

		return heCan;
	},
	formatDate: function (date) {
		'use strict';
		var dateFinale = '',
			dateSplit = date.split('/');
		//L'heure
		if (dateSplit.length === 3) {
			dateSplit[2] = dateSplit[2].split(' ');
			if (dateSplit[2].length > 1) {
				dateSplit[3] = dateSplit[2][1];
			}
			dateSplit[2] = dateSplit[2][0];
			if (dateSplit[0].length > 2) {
				//Date inverse to date normale
				dateFinale = dateSplit[2] + '/' + dateSplit[1] + '/' + dateSplit[0];
			} else {
				//Date normale to inverse
				dateFinale = dateSplit[0] + '/' + dateSplit[1] + '/' + dateSplit[2];
			}
			if (typeof (dateSplit[3]) !== 'undefined') {
				dateFinale = dateFinale + ' ' + dateSplit[3];
			}
		}
		return dateFinale;
	},
	countPhoto: function (visi) {
		'use strict';
		var tablo = this.corps.querySelectorAll('td'),
			nbPhotoJour = 0,
			monJour = '',
			monElem = document.createElement('div'),
			i = 0,
			n = 0,
			j = 0,
			m = 0;
		for (i = 0, n = tablo.length; i < n; i = i + 1) {
			nbPhotoJour = 0;
			monJour = tablo.item(i).getAttribute('data-date');
			for (j = 0, m = visi.catalogue.files.length; j < m; j = j + 1) {
				if (visi.catalogue.files[j].nom.search(monJour) !== -1) {
					nbPhotoJour = nbPhotoJour + 1;
				}
			}
			if (nbPhotoJour > 0) {
				monElem.className = 'badge';
				monElem.innerHTML = nbPhotoJour;
				tablo.item(i).classList.add('hasPhoto');
				tablo.item(i).appendChild(monElem);
				monElem = document.createElement('div');
			} else {
				tablo.item(i).classList.remove('hasPhoto');
			}
		}
	}
};

/*********** Init page ***********/
document.addEventListener('DOMContentLoaded', function () {
	'use strict';
	bodyProgress.set(33);
	fetch('config.json?rand=' + new Date().getTime(), {
		credentials: 'include',
		cache: 'no-cache'
	})
		.then(function (reponse) {
			bodyProgress.set(60);
			if (reponse.status === 200) {
				return reponse.json();
			} else {
				throw reponse.status;
			}
		})
		.then(function (json) {
			//console.info('Config base', json);
			bodyProgress.end();
			json.config.makeCron = false;
			maVisionneuse = new Visionneuse(json.config);
		})
		.catch(function (err) {
			bodyProgress.end();
			alert('Une erreur est survenue lors du chargement de la configuration');
			console.error(err);
		});

	var vancas = document.getElementById('photos_bloc');
	//Init canvas
	vancas.width = vancas.clientWidth;
	vancas.height = vancas.clientHeight;

	if (typeof (document.createElement('a').download) === 'undefined') {
		document.querySelectorAll('.save')[0].onclick = function () {
			window.open(this.href);
			return false;
		};
		document.querySelectorAll('.save')[1].onclick = function () {
			window.open(this.href);
			return false;
		};
	}

	if (document.location.hash === '#debug') {
		vancas.style.transform = 'scale(0.8)';
		vancas.style.backgroundColor = 'yellow';
		document.body.style.backgroundColor = 'ghostwhite';
		document.getElementById('punkt').style.display = 'block';
	}
});
